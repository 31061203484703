import React from 'react';
import './Rejseplan.css';
import SingleDisplay from './SingleDisplay';
// import data from "./data/stationer.json";
import data from "./data/allestationer.json";
// import WindowedSelect from 'react-windowed-select';
import Select from 'react-select';
import selectStyle from './Styles';

class Rejseplan extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			errorMessage: null,
			departures: [],
			stationValue: null,
			stations: data,
			menuIsOpen: false,
			stationList: null
		};

		// Get the stored stationValue from localstorage to restore previous sessions by setting the state.
		const storedStationValue = localStorage.getItem('stationValue');
		this.state.stationValue = storedStationValue;

		// Make it possible to call this.handleChange in callbacks 
		// by binding it to the function handleChange() from this class.
		this.handleChange = this.handleChange.bind(this);
	}

	// A helper method to determine when the input is active and thus set menuIsOpen state.
	// If menuIsOpen prop is set on the react-select component, the select will only open as soon as
	// the user starts typing.
	// NOT USED RIGHT NOW, SHOULD HAVE BEEN COMMENTED OUT.
	onInputChange = change => {
		if (change) {
			this.setState({ menuIsOpen: true });
		} else {
			this.setState({ menuIsOpen: false });
		}
	};

	// Run this function when the select changes value.
	handleChange(selected) {
		this.setState({ stationValue: selected.value }, this.loadDepartureBoard);
		setInterval(() => {
			this.loadDepartureBoard()
		}, 30000);
	}

	// Load the empty board immediately after mounting the component and before anything has been selected.
	componentDidMount() {
		this.createStationList();

		if (this.state.stationValue !== null) {
			this.loadDepartureBoard(); // Don't load anything before the input has been selected.
			setInterval(() => {
				this.loadDepartureBoard()
			}, 30000);
		} else {
			this.createEmptyBoard();
		}
	}

	// A helper function for populating the empty board with empty data,
	// until API data is fetched.
	createEmptyBoard = () => {
		let departures = [];
		for (let i = 0; i < 20; i++) {
			departures.push({
				time: "",
				finalStop: "",
				name: "",
				type: ""
			});
		}

		this.setState({ departures });
	}

	// Build a station picker list for the react-select component.
	createStationList = () => {
		let list = [];

		Object.keys(this.state.stations).map((key) => (
			list.push({ value: key, label: this.state.stations[key] })
		))

		this.setState({ stationList: list });
	}

	// Fetch API data from Rejseplanen and set the state to trigger rerenders.
	// This function also sets local storage environment locally on the user's
	// browser, to store the station that was selected.
	loadDepartureBoard = () => {

		fetch("/rejseplanen/departureBoard?id=" + this.state.stationValue + "&useBus=0&useMetro=0&format=json")
			.then(res => res.json())
			.then((result) => {
				if (result.DepartureBoard.error === "No journeys found") {
					this.createEmptyBoard()
				} else {
					this.setState({
						departures: result.DepartureBoard.Departure
					});
				}
			},
				(error) => {
					this.setState({
						errorMessage: error
					});
				});

		const stationValue = this.state.stationValue;
		localStorage.setItem('stationValue', stationValue);
	}

	render() {

		const error = this.state.errorMessage;
		const departures = this.state.departures;

		if (error) {
			return <div>Error: {error.message}</div>;
		} else {
			return (
				<div className="container">
					<Select
						// onInputChange={this.onInputChange}
						// menuIsOpen={this.state.menuIsOpen} // Only open menu when user starts to type. Disabled for now.
						placeholder={"Indtast station"}
						value={this.state.stationValue ? { value: this.state.stationValue, label: this.state.stations[this.state.stationValue] } : null}
						onChange={this.handleChange} 
						options={this.state.stationList}
						styles={selectStyle}
					/>

					<div className="legend">
						<div className="linje">Linje</div>
						<div className="station">Endestation</div>
						<div className="tid">Afgangstid</div>
					</div>

					<div className="departureBoard">
						{
							departures.slice(0, 10).map((departure, idx) => (
								<div key={idx} className="departure">
									<SingleDisplay displayValue={departure.name.toLowerCase()} type={departure.type} displayLength="3" />
									<SingleDisplay displayValue={departure.finalStop.toLowerCase()} displayLength="28" />
									<SingleDisplay displayValue={departure.time.toLowerCase()} displayLength="5" />
								</div>
							))
						}
					</div>
				</div>
			);
		}
	}

}

export default Rejseplan;