import React from 'react';
import Digit from './Digit'

class SingleDisplay extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            // "letterValue": " " // For testing
        };
    }

    // Helper functions for testing out data with a button instead of API
    // largerLetter = () => {
    //     this.setState({ "letterValue": "e" });
    // }
    //
    // smallerLetter = () => {
    //     this.setState({ "letterValue": "b" });
    // }

    render() {
        let displayChars = []; // The array to renders as a section.
        let type = this.props.type
        let formattedString = this.props.displayValue;

        switch(type) {
            case "M"    : formattedString = this.props.displayValue.substring(6, 8); break;
            case "S"    : break;
            case "REG"  : formattedString = "reg"; break;
            case "LYN"  : formattedString = "icl"; break;
            case "IC"   : formattedString = "ic" ; break;
            case "TOG"  : formattedString = "tog"; break;
            case "BUS"  : formattedString = "bus"; break;
            case "EXB"  : formattedString = "bus"; break;
            case "NB"   : formattedString = "bus"; break;
            case "TB"   : formattedString = "bus"; break;
            default: break;
        }

        // Build the list according to the displayValue string received.
        let lastLetterIdx = 0;
        for (lastLetterIdx; lastLetterIdx < Object.keys(formattedString).length; lastLetterIdx++) {
            displayChars.push(formattedString[lastLetterIdx]);
        }

        // Build the rest of the list according to specified displaylength.
        // This achieves uniform rows that all have the same length,
        // and where each character in the array can later be updated when the state changes.
        for (let j = lastLetterIdx; j < this.props.displayLength; j++) {
            displayChars.push(" ");
        }

        // Render the above character list as HTML elements.
        return (
            <div className="singleDisplay">
                {
                    displayChars.slice(0, this.props.displayLength).map((bogstav, idx) => (
                        <Digit key={idx} value={bogstav} />
                    ))
                }
            </div>
        );
    }

}

export default SingleDisplay;