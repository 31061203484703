/* Custom styles for react-select component. */

const selectStyle = {
	option: (provided, state) => ({
		...provided,
		borderBottom: '1px solid hsla(0, 0%, 12%, 100%)',
		padding: 20,
		fontSize: 24,
		height: 69,

		// Hide overflowing text...
		width: 200,
		textOverflow: 'ellipsis',
		overflow: 'hidden',
		whiteSpace: 'nowrap',
		
		color: state.isFocused ? 'hsla(0, 0%, 8%, 100%)' : null,
		backgroundColor: state.isFocused ? 'hsla(51, 94%, 56%, 100%)' : null,
	}),
	control: (provided, state) => ({
		...provided,
		background: 'hsla(0, 0%, 8%, 100%)',
		fontSize: 72,
		padding: 0,
		width: '100%',
		border: '0px',
		borderBottom: state.isFocused ? '2px solid hsla(51, 94%, 56%, 100%)' : '2px solid hsla(0, 0%, 12%, 100%)',
		'&:hover': { borderColor: 'hsla(51, 94%, 56%, 100%)' },
		borderRadius: 0,
		boxShadow: 'none',
		color: 'hsla(0, 0%, 100%, 100%)',
		cursor: 'pointer'
	}),
	indicatorSeparator: (provided, state) => ({
		...provided,
		display: 'none',
	}),
	valueContainer: (provided, state) => ({
		...provided,
		padding: 0,
	}),
	singleValue: (provided) => ({
		...provided,
		padding: 0,
		margin: 0,
		color: 'hsla(0, 0%, 100%, 100%)',
		"@media only screen and (max-width: 568px)": {
            fontSize: 48,
        }
	}),
	input: (provided) => ({
		...provided,
		'& input': {
			font: 'inherit',
		},
		color: 'hsla(0, 0%, 100%, 100%)',
		"@media only screen and (max-width: 568px)": {
            fontSize: 48,
        }
	}),
	placeholder: (provided) => ({
		...provided,
		"@media only screen and (max-width: 568px)": {
            fontSize: 48,
        }
	}),
	menu: (provided) => ({
		...provided,
		width: 200,
		marginTop: 0,
		borderRadius: 0,
		background: 'hsla(0, 0%, 8%, 100%)'
	}),
	dropdownIndicator: (provided, state) => ({
		color: 'hsla(0, 0%, 100%, 100%)',
		display: 'none'
	}),
	menuList: (provided, state) => ({
		...provided,
		paddingTop: 0,
		paddingBottom: 0
	}),
	noOptionsMessage: (provided) => ({
		...provided,
		padding: 20,
		height: 69,
		fontSize: 24
	})
}

export default selectStyle;