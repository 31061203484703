import React from 'react';
import ReactDOM from 'react-dom';
import Rejseplan from './Rejseplan';

ReactDOM.render(
	<React.StrictMode>
		<Rejseplan />
	</React.StrictMode>,
	document.getElementById('root')
);
