import React from 'react';

class Digit extends React.Component {

    // A counter to increment the current letter that should be selected from the alphabet.
    count = 0;

    // Create an alphabet where the index of each alphabet letter
    // can be looked up quickly (rather than looping over an array).
    alphabet = {
        " ": 0,
        "a": 1,
        "b": 2,
        "c": 3,
        "d": 4,
        "e": 5,
        "f": 6,
        "g": 7,
        "h": 8,
        "i": 9,
        "j": 10,
        "k": 11,
        "l": 12,
        "m": 13,
        "n": 14,
        "o": 15,
        "p": 16,
        "q": 17,
        "r": 18,
        "s": 19,
        "t": 20,
        "u": 21,
        "v": 22,
        "w": 23,
        "x": 24,
        "y": 25,
        "z": 26,
        "æ": 27,
        "ø": 28,
        "å": 29,
        ".": 30,
        ":": 31,
        "ä": 32,
        "ö": 33,
        "ü": 34,
        "1": 35,
        "2": 36,
        "3": 37,
        "4": 38,
        "5": 39,
        "6": 40,
        "7": 41,
        "8": 42,
        "9": 43,
        "0": 44,
        "(": 45,
        ")": 46
    };

    // Always construct new digits with the space " " value.
    constructor(props) {
        super(props);
        this.state = {
            "value": " "
        };
    }

    // Flip display from initial " " to new value with 40ms intervals.
    componentDidMount() {
        let newLetter = this.props.value;
        let currentLetter = this.state.value;
        this.setValue(currentLetter, newLetter);
    }

    // Flip display from current state to new value with 40ms intervals.
    componentDidUpdate() {
        let newLetter = this.props.value;
        let currentLetter = this.state.value;
        setTimeout(() => this.setValue(currentLetter, newLetter), 40);
    }

    // Flip through the the array of selected alphabet letters.
    setValue = (currentLetter, newLetter) => {

        // Create a list from the keyset of the alphabet dictionary.
        let alphabetArray = Object.keys(this.alphabet);

        // Determine how many places the digit needs to shift before it arrives at the new letter value.
        // This value is recalculated for every time the state is set.
        let shiftPlaces = (alphabetArray.length + this.alphabet[newLetter] - this.alphabet[currentLetter]) % alphabetArray.length;

        // this.state.value is flipped over to next alphabet entry,
        // while the difference between the current letter value and new letter value 
        // is not 0 (represented by shiftPlaces).

        if (shiftPlaces !== 0) {
            // The count will increase by one for each iteration,
            // until the count reaches the length of the alphabet array.
            // Then, the % (modulus) function will ensure that the count starts over with the first element
            // in the alphabet array.
            this.setState({ "value": alphabetArray[this.count % alphabetArray.length] });
            this.count++;
        }
    }

    // Render each digit with a single value.
    render() {
        return (
            <div className="digit">{this.state.value}</div>
        );
    }

}

export default Digit;